<template>
  <v-container fluid>
    <v-form ref="frmEmpresa">
      <v-row class="confEmpresa">
        <v-col cols="12" class="centrado"> <h1>DATOS DE LA EMPRESA</h1></v-col>
        <!-- <v-col cols="12" class="py-0">
          <v-autocomplete
            :items="$store.state.itemsDocumentsPais"
            item-text="description"
            item-value="id"
            outlined
            label="Tipo documento*"
            v-model="$store.state.configEmpresa.datoEmpresa.id_document"
            :rules="[(v) => !!v || 'Dato Requerido']"
          />
        </v-col> -->
        <!-- <v-col cols="12" class="py-0">
          <v-text-field
            outlined
            label="Número Documento*"
            placeholder="Número Documento*"
            v-model="$store.state.configEmpresa.datoEmpresa.document"
            :rules="[(v) => !!v || 'Dato Requerido']"
          ></v-text-field>
        </v-col> -->
        <v-col cols="12" class="py-0">
          <v-text-field
            outlined
            label="Nombre de la Empresa*"
            placeholder="Nombre de la Empresa*"
            v-model="$store.state.configEmpresa.datoEmpresa.trade_name"
            :rules="[(v) => !!v || 'Dato Requerido']"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="py-0">
          <v-file-input
            label="Logo"
            placeholder="Logo"
            type="file"
            outlined
            show-size
            append-icon="mdi-camera-outline"
            prepend-icon=""
            v-model="$store.state.configEmpresa.datoEmpresa.img"
            @change="cargarLogo()"
          ></v-file-input>
          <p class="ml-5">
            Te ayudará a emitir tus documentos de una forma perzonalizada
          </p>
        </v-col>
        <v-col cols="4" class="py-0" v-if="$store.state.configEmpresa.datoEmpresa.logo">
          <v-img
            :lazy-src="$store.state.configEmpresa.datoEmpresa.logo"
            max-height="150"
            max-width="250"
            :src="$store.state.configEmpresa.datoEmpresa.logo"
            :alt="`Logo ${$store.state.configEmpresa.datoEmpresa.trade_name}`"
          ></v-img>
        </v-col>

        <v-col cols="12" class="py-0">
          <v-autocomplete
            label="País"
            :items="$store.state.itemsPais"
            outlined
            item-value="id"
            item-text="name"
            v-model="$store.state.configEmpresa.datoEmpresa.id_pais"
            :rules="[(v) => !!v || 'Dato Requerido']"
          />
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            outlined
            label="Dirección*"
            placeholder="Dirección*"
            v-model="$store.state.configEmpresa.datoEmpresa.address"
            :rules="[(v) => !!v || 'Dato Requerido']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0 centrado">
          <v-btn
            :loading="loading"
            dark
            color="#2C3AB8"
            @click="continuarLlenadoPersona()"
          >
            Guardar y Continuar
          </v-btn>
        </v-col>
        <!-- <v-col cols="4" class="py-0 derecha">
          <v-btn @click="omitirLlenadoEmpresa()" dark color="#A4A6B5"
            >Omitir</v-btn
          >
        </v-col> -->
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      logo: "",
    };
  },
  methods: {
    ...mapActions(["actualizarDatoEmpresa", "_uploadFile"]),
    async cargarLogo() {
      await this._uploadFile(this.$store.state.configEmpresa.datoEmpresa.img);
      this.$store.state.configEmpresa.datoEmpresa.id_logo =
        this.$store.state.files.datosPath.insertid;
      this.$store.state.configEmpresa.datoEmpresa.logo =
        this.$store.state.files.datosPath.ruta;
      // console.log(this.$store.state.configEmpresa.datoEmpresa);
    },
    async continuarLlenadoPersona() {
      if (this.$refs.frmEmpresa.validate()) {
        this.loading = true;
        await this.actualizarDatoEmpresa();

        this.$store.state.enterprises.datoEmpresaFlag = false;
        this.$store.state.enterprises.datoAdminFlag = true;
        this.loading = false;
      }
    },
    async omitirLlenadoEmpresa() {
      this.loading = true;

      this.$store.state.enterprises.datoEmpresaFlag = false;
      this.$store.state.enterprises.datoAdminFlag = true;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.derecha {
  align-content: right !important;
  text-align: right !important;
}
.izquierda {
  align-content: left !important;
  text-align: left !important;
}
.centrado {
  align-content: center !important;
  text-align: center !important;
}
.logo {
  max-width: 25%;
  background: brown;
}
.blue {
  background: none !important;
  color: #09305e !important;
}
.mt-info {
  padding: 0% 5%;
  margin: 25% 0 0 0;
}
.confEmpresa {
  min-height: 100vh;
  padding: 0 0 200px 0;
}
</style>
